import React, { useState } from 'react'
import Classes from './About.module.css'
import Profile1Popup from './Popup/Profile1Popup'
import Profile3Popup from './Popup/Profile3Popup'
import Profile2Popup from './Popup/Profile2Popup'
const icon1 = '/about-icon1.png'
const icon2 = '/about-icon2.png'
const icon3 = '/about-icon3.png'
const profile1 = '/profile1.png'
const profile2 = '/profile2.png'
const profile3 = '/profile3.png'

export default function SectionC() {
    const [openProfilePopup1, setopenProfilePopup1] = useState(false);
    const handleCloseProfilePopup1 = () => {
        setopenProfilePopup1(false);
    }
    const handleOpenPopup1 = () => {
        setopenProfilePopup1(true);
    }

    const [openProfilePopup2, setopenProfilePopup2] = useState(false);
    const handleCloseProfilePopup2 = () => {
        setopenProfilePopup2(false);
    }
    const handleOpenPopup2 = () => {
        setopenProfilePopup2(true);
    }

    const [openProfilePopup3, setopenProfilePopup3] = useState(false);
    const handleCloseProfilePopup3 = () => {
        setopenProfilePopup3(false);
    }
    const handleOpenPopup3 = () => {
        setopenProfilePopup3(true);
    }


    return (
        <>
            {openProfilePopup1 && <Profile1Popup closePopup={handleCloseProfilePopup1} />}
            {openProfilePopup2 && <Profile2Popup closePopup={handleCloseProfilePopup2} />}
            {openProfilePopup3 && <Profile3Popup closePopup={handleCloseProfilePopup3} />}
            <div className={Classes.sectionC} >
                <h1>
                    Wir stehen für
                </h1>
                <div className={Classes.miniContainer} >
                    <div className={`${Classes.flexAlign} ${Classes.width27}`} >
                        <div className={Classes.flexCenter} >
                            <img src={icon1} className={Classes.icon} />
                        </div>
                        <div>
                            <h2>Präzision</h2>
                            <h5>
                                Um den individuellen Herausforderungen eines <br />
                                jeden Spielers gerecht zu werden, ist eine <br />
                                detaillierte Leistungsdiagnostik unumgänglich.
                            </h5>
                        </div>
                    </div>
                    <div className={`${Classes.flexAlign} ${Classes.width27}`} >
                        <div className={Classes.flexCenter} >
                            <img src={icon2} className={Classes.icon} />
                        </div>
                        <div>
                            <h2>Potenzial</h2>
                            <h5>
                                Um euer volles Potenzial auszuschöpfen, <br />
                                unterstützt euch unser erfahrenes Team mit <br />
                                wissenschaftlich fundierten Ansätzen, damit <br />
                                ihr eure sportlichen Ziele erreicht.
                            </h5>
                        </div>
                    </div>
                    <div className={`${Classes.flexAlign} ${Classes.width27}`} >
                        <div className={Classes.flexCenter} >
                            <img src={icon3} className={Classes.icon} />
                        </div>
                        <div>
                            <h2>Wissenschaft</h2>
                            <h5>
                                Um die Effektivität unserer Trainingsinterventionen <br />
                                zu gewährleisten, sind alle angewandten Methoden <br />
                                empirisch fundiert und praxisbewährt.
                            </h5>
                        </div>
                    </div>
                </div>
                <div className={Classes.miniContainer2} >
                    <h1>Das Team</h1>
                    <div className={Classes.teamContainer} >
                        <div>
                            <div className={Classes.card_img} >
                                <img src={profile1} className={Classes.card_inner_img} onClick={handleOpenPopup1} />
                            </div>
                            <h5>Lukas <span style={{ color: '#747474' }} >Gründer, Atlehtiktrainer</span> </h5>
                        </div>
                        {/* <div>
                            <div className={Classes.card_img} >
                                <img src={profile2} className={Classes.card_inner_img} onClick={handleOpenPopup2} />
                            </div>
                            <h5>Fisnik <span style={{ color: '#747474' }} >Marketing</span> </h5>
                        </div> */}
                        <div >
                            <div className={Classes.card_img} >
                                <img src={profile3} className={Classes.card_inner_img} onClick={handleOpenPopup3} />
                            </div>
                            <h5>Thorge <span style={{ color: '#747474' }} >Atlehtiktrainer</span> </h5>
                        </div>
                    </div>
                </div>
                <div className={`${Classes.SectionDConBottom} ${Classes.mt3rem} `} >
                    <button> <h5>Kontakt</h5> </button>
                </div>
            </div>
        </>
    )
}
